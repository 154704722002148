<template>
  <div
    id="login"
    :style="{
      'background-position': 'center center',
      'background-image': 'url(' + backgroundImg(advert.image) + ')',
      height: '604px',
    }"
  >
    <div class="wrap">
      <div class="login_item">
        <div class="title">会员登录</div>
        <div class="inputs">
          <img src="@/assets/img/login-01.png" alt="" />
          <input
            @keyup.enter="submit"
            v-model="phone"
            type="text"
            placeholder="手机"
          />
        </div>
        <div class="inputs">
          <img src="@/assets/img/login-03.png" alt="" />
          <input
            @keyup.enter="submit"
            v-model="password"
            type="password"
            placeholder="密码"
          />
        </div>
        <div class="inputs">
          <i class="icon-font el-icon-picture"></i>
          <input
            type="text"
            @keyup.enter="submit"
            v-model="verifyCode"
            placeholder="图片验证码"
            maxlength="6"
          />
          <div
            class="imgCodeMode"
            @click="getVerifyCode"
            v-loading="verifysignLoding"
          >
            <img :src="codeUrl" alt="" />
          </div>
        </div>
        <div class="btn" @click="submit">登 录</div>
        <div class="other">
          <router-link to="/forget_password">忘记密码</router-link>
          <router-link to="/register">注册账号</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { setToken } from "@/utils/auth";
export default {
  name: "login",
  data() {
    return {
      phone: "",
      password: "",
      codeUrl: "",
      verifyCode: "",
      verifysign: "",
      verifysignLoding: false,
      loading: false,
      advert: {},
    };
  },
  created() {
    this.getVerifyCode();
    this.getAdvertList();
    this.IsPC();
  },
  mounted() {},
  watch: {},
  methods: {
    IsPC() {
      var userAgentInfo = navigator.userAgent;
      var Agents = new Array(
        "Android",
        "iPhone",
        "SymbianOS",
        "Windows Phone",
        "iPad",
        "iPod"
      );
      var flag = true;
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false;
          break;
        }
      }
      if (!flag) {
        this.$router.push("/m_login");
      }
    },
    backgroundImg(value) {
      var host = document.location.host;
      var result = "";
      if (value && value.indexOf("files") != -1) {
        if (host.search("192") != -1) {
          result = "http://192.168.0.172:8080/" + value;
        } else {
          result = document.location.protocol + "//" + host + "/" + value;
        }
      } else {
        result = value;
      }
      return result;
    },
    submit() {
      if (this.loading) return;
      this.loading = true;
      const loading = this.$loading();


      let reg = 11 && /^[1][0-9][0-9]{9}$/;
      if (!reg.test(this.phone)) {
        this.$message.warning("请输入正确的手机号码");
        this.loading = false;
        loading.close();
        return;
      }
      if (this.password == "") {
        this.$message.warning("请输入密码");
        this.loading = false;
        loading.close();
        return;
      }
      if (this.verifyCode == "") {
        this.$message.warning("请输入图片验证码");
        this.loading = false;
        loading.close();
        return;
      }
      this.$request.post({
        url: "/ReportApi/business/user/login",
        params: {
          account: this.phone,
          password: this.password,
          loginType: 1,
          verifyCode: this.verifyCode,
          verifySign: this.verifysign,
        },
        success: (res) => {
          var arr = {};
          arr.info = res;
          if (res.levelId == 0) {
            arr.level = "普通会员";
          } else if (res.levelId == 1) {
            arr.level = "高级会员";
          } else {
            arr.level = "最高会员";
          }



          var token = res.token;
          setToken(token);
          localStorage.setItem("dftToken", res.token);

          localStorage.setItem("phone", arr.info.phone);
          localStorage.setItem("ids", arr.info.id);
          this.$store.commit("getUserInfo", arr);
          this.$store.commit("setIsLogin", true);
          this.$router.push("/member/index");
        },
        error: (e) => {
          this.getVerifyCode();
        },
        finally: () => {
          this.loading = false;
          loading.close();
        },
      });
    },
    getVerifyCode() {
      this.verifysignLoding = true;
      this.$request.post({
        url: "/ReportApi/basic/system/getVerifyCode",
        success: (res) => {
          let codeImg = res.value;
          this.codeUrl = codeImg;
          this.verifysign = res.key;
          this.verifysignLoding = false;
        },
      });
    },
    getAdvertList() {
      this.$request.post({
        url: "/ReportApi/business/advert/getAdvertInfo",
        params: {
          type: 2,
          tenantId: localStorage.getItem("tenantInfoid"),
        },
        success: (res) => {
          if (res.length > 0) {
            this.advert = res[0];
          }
        },
        finally: () => {},
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#login {
  background: #3c92ff;
  .wrap {
    position: relative;
    img {
      display: block;
    }
  }
  .login_item {
    position: absolute;
    width: 350px;
    right: 0;
    top: 48px;
    background: #fff;
    padding: 30px;
    box-sizing: border-box;
    .title {
      text-align: center;
      font-size: 20px;
      color: #8c8c8c;
      line-height: 40px;
      margin-bottom: 30px;
    }
    .inputs {
      position: relative;
      margin-bottom: 15px;
      border: 1px solid #e1e1e1;
      height: 36px;
      input {
        border: none;
        color: #666;
        outline: none;
        height: 36px;
        font-size: 16px;
        width: 240px;
        vertical-align: bottom;
      }
      img {
        width: 25px;
        display: inline-block;
        margin: 0 5px;
      }
      .icon-font {
        font-size: 18px;
        margin: 8px;
        color: #cacaca;
      }
      .imgCodeMode {
        height: 36px;
        position: absolute;
        top: 0;
        right: 0;
        img {
          margin: 0;
          height: 100%;
          width: auto;
          display: block;
        }
        &:hover {
          cursor: pointer;
        }
      }
    }
    .btn {
      width: 100%;
      line-height: 38px;
      background: #ea242f;
      color: #fff;
      text-align: center;
      border-radius: 5px;
      &:hover {
        cursor: pointer;
      }
    }
    .other {
      margin-top: 10px;
      font-size: 12px;
      color: #666;
      a {
        &:last-child {
          padding-left: 5px;
        }
        &:hover {
          cursor: pointer;
          color: #ea242f;
        }
      }
    }
  }
}
</style>
